import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="copy-button"
export default class extends Controller {
  static targets = ["link"];

  linkCopy() {
    navigator.clipboard.writeText(this.linkTarget.href);
    const flash = document.getElementById("js-flash");
    let container = document.createElement("div");
    container.innerHTML = `
      <div id="js-flash" class="fixed top-24 translate-x-1/2 right-6 sm:translate-x-0 z-30 w-fit max-w-[90%] sm:max-w-1/2 py-4 px-6 flex justify-center items-center bg-white shadow-lg border border-line-100 rounded-[3px] animate-flash">
        <div class="my-auto mx-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.456" height="16"><path data-name="Icon awesome-check" d="M7.287 15.686.314 8.713a1.073 1.073 0 0 1 0-1.517l1.517-1.517a1.073 1.073 0 0 1 1.517 0l4.7 4.7L18.107.314a1.073 1.073 0 0 1 1.517 0l1.517 1.517a1.073 1.073 0 0 1 0 1.517L8.8 15.686a1.073 1.073 0 0 1-1.513 0Z"/></svg>
        </div>
        <p class="text-base font-bold text-dark-blue-200 !leading-none">マイページのURLをコピーしました</p>
      </div>
    `;
    document.body.insertAdjacentElement("afterbegin", container);
  }
}
