import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search-form"
export default class extends Controller {
  static targets = [
    "checkbox",
    "constructionTab",
    "constructionButton",
    "placeTab",
    "placeButton",
  ];

  allUncheck() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    });
    const priceForm = document.getElementById("js-price-form");
    if (priceForm) {
      priceForm.value = "";
    }
  }

  onClickConstructionTab() {
    this.constructionTabTarget.classList.remove("hidden");
    this.placeTabTarget.classList.add("hidden");
    this.constructionButtonTarget.setAttribute("aria-selected", "true");
    this.placeButtonTarget.setAttribute("aria-selected", "false");
  }

  onClickPlaceTab() {
    this.constructionTabTarget.classList.add("hidden");
    this.placeTabTarget.classList.remove("hidden");
    this.constructionButtonTarget.setAttribute("aria-selected", "false");
    this.placeButtonTarget.setAttribute("aria-selected", "true");
  }
}
