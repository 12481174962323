import { Controller } from "@hotwired/stimulus";
import "flowbite/dist/flowbite";

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["svg", "dropdownMenu", "toggleMargin", "button"];

  connect() {
    const targetEl = this.dropdownMenuTarget;
    const triggerEl = this.buttonTarget;
    const options = {
      onHide: () => {
        this.svgTarget.classList.remove("rotate-180");
        if (this.hasToggleMarginTarget) {
          this.toggleMarginTarget.classList.remove(
            "md:mb-[600px]",
            "mb-[500px]"
          );
        }
      },
      onShow: () => {
        this.svgTarget.classList.add("rotate-180");
        if (this.hasToggleMarginTarget) {
          this.toggleMarginTarget.classList.add("md:mb-[600px]", "mb-[500px]");
        }
      },
    };
    const dropdown = new Dropdown(targetEl, triggerEl, options);
  }
}
