import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["priceSettingForm", "priceForm", "formLabel"];

  onChangeStandardPriceSetting() {
    if (this.priceSettingFormTarget.value === "specified_price") {
      this.priceFormTarget.style.display = "flex";
      this.formLabelTarget.textContent = "円/日";
    } else if (this.priceSettingFormTarget.value === "estimate") {
      this.priceFormTarget.style.display = "none";
    }
  }

  onChangePremiumPriceSetting() {
    if (this.priceSettingFormTarget.value === "specified_price") {
      this.priceFormTarget.style.display = "flex";
      this.formLabelTarget.textContent = "円";
    } else if (this.priceSettingFormTarget.value === "desired_price") {
      this.priceFormTarget.style.display = "flex";
      this.formLabelTarget.textContent = "円以下";
    } else if (this.priceSettingFormTarget.value === "estimate") {
      this.priceFormTarget.style.display = "none";
    }
  }
}
