import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="mypage_qualification"
export default class extends Controller {
  static targets = ["hidden", "button"];

  delete(e) {
    const order = e.target.value;
    this.hiddenTargets[order].removeAttribute("value");
    this.buttonTargets[order].classList.add("hidden");
  }
}
