import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["output", "actionBtn"];

  preview(e) {
    const [file] = e.target.files;
    if (file) {
      this.outputTarget.setAttribute("src", URL.createObjectURL(file));
      this.outputTarget.nextElementSibling.classList.remove("hidden");
    }
  }

  selectImage(e) {
    const imageName = e.target.value;
    const newHref = `/mypage/image_attach?filename=${imageName}`;
    this.actionBtnTarget.setAttribute("href", newHref);
  }
}
