import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-form"
export default class extends Controller {
  static targets = ["form", "hidden"];
  toggle() {
    if (this.formTarget.tagName == "SELECT") {
      this.formTarget.classList.toggle("bg-white", "border-console");
      this.formTarget.disabled = !this.formTarget.disabled;
      if (this.formTarget.disabled) {
        this.formTarget.classList.add("bg-[#E4DDDD]", "border-0");
      } else {
        this.formTarget.classList.remove("bg-[#E4DDDD]", "border-0");
      }
    } else {
      this.formTarget.classList.toggle("bg-white", "border-console");
      this.formTarget.readOnly = !this.formTarget.readOnly;
      if (this.formTarget.readOnly) {
        this.formTarget.classList.add("bg-[#E4DDDD]", "border-0");
      } else {
        this.formTarget.classList.remove("bg-[#E4DDDD]", "border-0");
      }
    }
  }

  onSelect() {
    this.hiddenTarget.value = this.formTarget.value;
  }
}
