import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="multi-select-form"
export default class extends Controller {
  static targets = ["text", "checked"];
  static values = { title: String, groupName: String };

  onChange(e) {
    const brotherCheckboxes =
      e.target.parentNode.parentNode.parentNode.querySelectorAll(
        `[data-multi-select-form-group-id-value="${e.target.dataset.multiSelectFormGroupIdValue}"]`
      );
    const checkArray = Object.values(brotherCheckboxes);
    const groupCheckbox = document.getElementById(
      `check-${this.groupNameValue}-${e.target.dataset.multiSelectFormGroupIdValue}`
    );
    if (checkArray.every((e) => e.checked == true)) {
      groupCheckbox.checked = true;
    } else {
      groupCheckbox.checked = false;
    }
    if (this.hasCheckedTarget && this.hasTextTarget) this.textChange();
  }

  onAllCheck(e) {
    const rootCheckbox = e.target;
    const childCheckboxes =
      rootCheckbox.parentNode.parentNode.querySelectorAll("input");
    childCheckboxes.forEach((e) => {
      e.checked = rootCheckbox.checked;
    });
    this.textChange();
  }

  onGroupCheck(e) {
    const parentCheckbox = e.target;
    const childCheckboxes =
      parentCheckbox.parentNode.nextElementSibling.querySelectorAll("input");
    childCheckboxes.forEach((e) => {
      e.checked = parentCheckbox.checked;
    });
    if (this.hasCheckedTarget && this.hasTextTarget) this.textChange();
  }

  textChange() {
    const count = this.checkedTargets.filter((target) => target.checked).length;
    if (count) {
      this.textTarget.textContent = `${count}個の${this.titleValue}を選択中`;
    } else {
      this.textTarget.textContent = `選択中の${this.titleValue}はありません。`;
    }
  }

  connect() {
    if (this.hasCheckedTarget && this.hasTextTarget) this.textChange();
  }
}
