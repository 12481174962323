import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fixed"];

  disappearFixed() {
    const footerTop = document
      .getElementById("js-footer")
      .getBoundingClientRect().top;
    const windowHeight = document.documentElement.clientHeight;
    if (footerTop < windowHeight) {
      this.fixedTarget.classList.add("hidden");
    } else {
      this.fixedTarget.classList.remove("hidden");
    }
  }
}
