import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="narrowing"
export default class extends Controller {
  static targets = ["input", "select"];

  onChange() {
    const path = `/qualification_divisions/${this.inputTarget.value}/qualifications.json`;
    choiceChange(path, this.selectTarget);
    this.selectTarget.classList.add("text-[#333333]");
    this.selectTarget.classList.remove("text-line-200");
  }
}

async function choiceChange(path, target) {
  try {
    const response = await fetch(path);
    const qualifications = await response.json();
    while (target.firstChild) {
      target.removeChild(target.firstChild);
    }
    target.insertAdjacentHTML(
      "afterbegin",
      `<option selected="selected" value="">資格名</option>`
    );
    qualifications.forEach((qualification) => {
      target.insertAdjacentHTML(
        "afterbegin",
        `<option value='${qualification.id}'>${qualification.title}</option>`
      );
    });
  } catch (err) {
    console.error(err);
  }
}
