import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="period-appearance"
export default class extends Controller {
  static targets = ["division", "input", "hidden"];

  onChange() {
    const path = `/qualification_divisions/${this.divisionTarget.value}/qualifications/${this.inputTarget.value}.json`;
    choiceChange(path, this.hiddenTarget);
  }
}

async function choiceChange(path, target) {
  try {
    const response = await fetch(path).then((response) => response.json());
    if (response.qualification.have_period == true) {
      target.classList.remove("hidden");
    } else {
      target.classList.add("hidden");
    }
  } catch (err) {
    console.error(err);
  }
}
